import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

export default async function ({ game }): Promise<void> {
  const { $gameService } = useServices();
  const auth = useAuthStore();
  const site = useSiteStore();

  const route = useRoute();

  const response = await $gameService.addGameFavourites({
    channel: 'WebDesktop',
    languageCode: 'en-US',
    vertical: site.getCurrentVertical(route).Mapping,
    currency: 'USD',
    token: auth.access_token,
    game: game,
  });
}
